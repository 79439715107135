<template>
    <div id="Commision" class="has_fixed_bottom_box">
        <div class="wallet">
            <div class="flex flex-column w-100 text-center py-4">
                <h4 class="mb-1">{{assets}} {{title}}</h4>
                <h5 class="main_txt small mb-0 text-center font-weight-bold">= {{usd}} $</h5>
                <!-- <small>1 {{title}} = {{rate}} USDP</small> -->
                <small class="lock" v-if="lock > 0"><i class="far fa-solid fa-lock"></i> LOCK Balance : {{lock}} </small>
            </div>
        </div>
        <div class="botC">
            <p class="p-3 mb-0 small border-bottom">{{$t("coin.2")}}</p>
            <div class="container pt-3 history">
                <div class="history_box">
                    <ul class="pl-0">
                        <li class="d-flex align-items-center mb-4" v-for="(item, index) in history" :key="index" @click="infoPpOn(item)">                            
                            <span class="txt_icon" 
                                :class="{'send' : item.type == 'W', 
                                'receive' : item.type == 'D' }">

                                <i class="far fa-ellipsis-h" v-if="item.hash===null"></i>
                                <i class="far fa-check" v-else-if="item.hash!==null"></i>
                                <i class="far fa-times" v-else></i>

                            </span>

                            <div class="left">
                                <p class="addr" v-if="item.type == 'W'">{{typeName(item.type)}}</p>
                                <p class="addr" v-else>{{typeName(item.type)}}</p>
                                <p class="date pale_txt">{{item.create_time}}</p>
                            </div>
                            <div class="right">
                                <p>{{item.amount}} {{item.symbol}}</p>
                                <p class="main_txt mr-0">{{usdCalc(item.amount)}} $</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="infoPp" v-if="infoPp">
                <div class="dimm" @click="infoPpOff()"></div>
                <div class="content">
                    <div class="form-group line">
                        <label>{{$t("coin.5")}}</label>
                        <input type="text" class="form-control" :value="typeName(selected_item.type)" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t("coin.19")}}</label>
                        <div class="r_btn">
                            <input type="text" class="form-control" :value="selected_item.from_address" disabled>
                            <button class="btn" @click="CopyWallet(selected_item.from_address)">
                                <i class="far fa-copy"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group line">
                        <label>{{$t("coin.20")}}</label>
                        <div class="r_btn">
                            <input type="text" class="form-control" :value="selected_item.to_address" disabled>
                            <button class="btn" @click="CopyWallet(selected_item.to_address)">
                                <i class="far fa-copy"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group line">
                        <label>{{$t("coin.3")}}</label>
                        <input type="text" class="form-control" :value="selected_item.amount+' '+selected_item.symbol" disabled>
                    </div>
                    <div class="form-group line">
                        <label>{{$t("coin.4")}}</label>
                        <input type="text" class="form-control" :value="selected_item.create_time" disabled>
                    </div>
                    <div class="form-group line" v-if="selected_item.hash !== null">
                        <label>Hash</label>
                        <div class="r_btn">
                            <input type="text" class="form-control" :value="selected_item.hash" disabled>
                            <button class="btn" @click="hashCheck(selected_item.hash)">
                                <i class="far fa-share-square"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group line">
                        <label>{{$t("coin.6")}}</label>
                        <input type="text" class="form-control" :value="statusName(selected_item.hash)" disabled>
                    </div>
                </div>
            </div>
            <div class="fixed_bottom_box p-3 d-flex">
                <router-link :to="`/${$i18n.locale}/page/coin/${net}/${title}/receive`" class="btn btn-block btn-primary mr-2"><i class="arr_icon far fa-arrow-down"></i><small>{{$t("coin.8")}}</small></router-link>
                <router-link :to="`/${$i18n.locale}/page/withdrawals/${net}/${title}`" class="btn btn-block btn-primary mt-0 "><i class="arr_icon far fa-arrow-up"></i><small>{{$t("coin.9")}}</small></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            net: this.$route.params.net,
            title: this.$route.params.idx,
            prevP: false,
            pageD: true,
            assets:0,
            usd:0,
            lock: 0,
            rate: {},
            infoPp: false,
            history: []
        }
    },
    created() {
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
    },
    methods:{          
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){                         
                        this.TokenHistory();
                        this.CoinRate();
                        this.GetWalletInfoToken();
                        this.GetWalletLock();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },        
        GetWalletInfoToken(){            
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.assets = res.data.balance;
                        this.usd = (parseFloat(this.assets) * parseFloat(this.rate.usd)).toFixed(6);
                    }
                }
            })
        },     
        GetWalletLock() {
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/lock/check`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.lock = res.data.lock;
                    }
                }
            })
        },
        TokenHistory(){
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/transaction/list/each`,{symbol}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.history = res.data.list;
                    }
                }
            })
        },
        CoinRate() {
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/rate/each`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.rate = res.data.rate;
                    }
                }
            })
        },
        usdCalc(value){
            const rate = this.rate;
            return (parseFloat(rate.usd) * parseFloat(value)).toFixed(6);
        },
        CopyWallet: function (addr) {
            this.$copyText(addr).then( 
            () =>{
                this.$fire({title:this.$t('mywallet.18'),html : addr});
            })
        },
        typeName: function(val){
            if(val == 'W') return this.$t("coin.9")
            else if(val == 'D') return this.$t("coin.8")
        },
        statusName: function(val){            
            if(val == null) return this.$t("coin.14")
            else if(val != null) return this.$t("coin.13")
            else return this.$t("coin.15")
        },
        hashCheck: function(hash) {
            const net = this.selected_item.net;
            if(net === 'BTC') window.open('https://www.blockchain.com/btc/tx/'+hash, '_blank');
            else if(net === 'ETH') window.open('https://etherscan.io/tx/'+hash, '_blank');
            else if(net === 'BNB') window.open('https://bscscan.com/tx/'+hash, '_blank');
            else if(net === 'AVAX') window.open('https://snowtrace.io/tx/'+hash, '_blank');
        },
        infoPpOn: function(item){
            this.selected_item = item;
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
    }
}
</script>

<style scoped>
.fixed_bottom_box{
    background-color: #fff;
}
.history{
    min-height: auto;
    overflow-y: auto;
    box-sizing: border-box;
    /* height: calc(100vh - 114px - 156px); */
    max-height: calc(100vh - 270px);
    height: calc(100vh - 0px - 156px);
}
.botC{
    background-color: #fff;
    border-radius: 25px 25px 0 0;
}
.detail_date{
    color: #999;
}
.form-group.line{
    margin-bottom: 10px;
}
.form-group.line input{
    border-bottom: 0;
    font-size: 14px;
    margin-bottom: 0;
}
.arr_icon{
    vertical-align: middle;
    margin-right: 10px;
}
.lock {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #ff7b7b;
}
</style>