import { render, staticRenderFns } from "./CoinHistory.vue?vue&type=template&id=eea4cf1e&scoped=true&"
import script from "./CoinHistory.vue?vue&type=script&lang=js&"
export * from "./CoinHistory.vue?vue&type=script&lang=js&"
import style0 from "./CoinHistory.vue?vue&type=style&index=0&id=eea4cf1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eea4cf1e",
  null
  
)

export default component.exports