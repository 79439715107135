<template>
    <div id="Receive" class="has_fixed_bottom_box">
        <div class="container">

            <div class="px-5">
                <h6 class="text-center mt-4">{{$t("coin.1")}}</h6>
    
                <div class="qrcode_box mb-2" v-if="otp_box">
                    <vue-qrcode :value="address" style='width:100%'/>
                </div>
    
                <p class="font-weight-bold text-center break-all">{{address}}</p>
    
                <p class="text-center small mt-5 main_txt">{{$t("coin.16")}}</p>
            </div>
            <div class="fixed_bottom_box p-3 d-flex">
                <a class="btn btn-block btn-primary mr-2" @click="CopyWallet"><small>{{$t("coin.17")}}</small></a>
                <a class="btn btn-block btn-primary mt-0 " @click="ShareButton"><small>{{$t("coin.18")}}</small></a>
            </div>
        </div>
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
    components: {
        VueQrcode,
    },
    data(){
        return{
            net: this.$route.params.net,
            token: this.$route.params.idx,
            title:this.$t("menu.14"),
            prevP: false,
            address: '',
            otp_box: false,
            prevP: false,
            pageD: false,
        }
    },
    mounted(){
        const idx = this.$route.params.idx;
        if(idx === 'Wallet') this.title = this.$t("menu.14");
        else this.title = this.$t("coin.8");

        this.$store.commit("chgTitle",this.token + " " + this.title);
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },    
    methods: {             
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){    
                    this.GetWalletInfo();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        ShareButton() {
            try {
                if( /Android/i.test(navigator.userAgent)) {
                    window.HybridApp.shareText(this.address);
                }
                else{
                    window.webkit.messageHandlers.HybridApp.shareText(this.address);
                }
            }catch(e) {
                console.log("shareText "+e);
            }				
        },     
        GetWalletInfo(){
            const net = this.net;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/wallet_info/symbol`,{net}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.address = res.data.address;
                        this.otp_box = !this.otp_box;
                    }
                }
            })
        },
        CopyWallet: function () {
            this.$copyText(this.address).then( 
            () =>{
                this.$fire({title:this.$t('commision.4'),html : this.address});
            })
        },
    },
}
</script>

<style scoped>
#Receive{
    /* height: calc(100vh - 50px); */
    display: flex;
    align-items: center;
}
</style>